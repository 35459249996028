import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
    HStack,
    Spinner,
    Table,
    TableCaption,
    TableContainer,
    Tag,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { supabase } from '../supabaseClient'
import ChevronRight from '../icons/ChevronRight'

interface IBooklistEntry {
    texttitle: string
    bookname: string
    textseo: string
    bookseo: string
}

const SelectBook = () => {
    const { textId } = useParams()
    const toast = useToast()
    const navigate = useNavigate()
    const toastId = 'error-books-toast'

    const [loading, setLoading] = useState(false)
    const [bookList, setBookList] = useState([] as IBooklistEntry[])

    const loadData = useCallback(async () => {
        setLoading(true)

        const { data, error } = await supabase.rpc('get_distinct_books', {
            param: textId,
        })

        setLoading(false)

        if (error && !toast.isActive(toastId)) {
            console.log(error)
            toast({
                id: toastId,
                title: 'Issue',
                description: 'Could not load texts',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
            })
        }

        if (data) {
            setBookList(data)
        }
    }, [textId, toast])

    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <HStack alignItems="flex-start" width="100%">
            <VStack
                spacing="8"
                background="white"
                padding={{
                    base: '16px 8px',
                    md: '24px',
                }}
                borderRadius={{
                    base: '8px',
                    md: '24px',
                }}
                minWidth={{
                    base: '100%',
                    md: 'container.md',
                }}
                width="100%"
            >
                <Heading textAlign="center" color={'twitter.800'}>
                    Books
                </Heading>
                <Breadcrumb spacing="8px" separator={<ChevronRight />}>
                    <BreadcrumbItem>
                        <BreadcrumbLink to="/texts" as={Link}>
                            Texts
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {!loading ? (
                            <Tag _hover={{ cursor: 'default' }}>
                                {bookList[0]?.texttitle}
                            </Tag>
                        ) : (
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="teal.500"
                                size="sm"
                            />
                        )}
                    </BreadcrumbItem>
                </Breadcrumb>
                <TableContainer width="100%">
                    <Table variant="simple">
                        <TableCaption>Current online corpus</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {loading && (
                                <Tr>
                                    <Td>
                                        <Spinner
                                            thickness="4px"
                                            speed="0.65s"
                                            emptyColor="gray.200"
                                            color="teal.500"
                                            size="md"
                                        />
                                    </Td>
                                </Tr>
                            )}
                            {bookList.map(({ bookname, bookseo, textseo }) => (
                                <Tr
                                    _hover={{
                                        backgroundColor:
                                            'var(--chakra-colors-gray-100)',
                                        cursor: 'pointer',
                                    }}
                                    key={`texts-${textseo}-${bookseo}`}
                                    onClick={() => {
                                        if (textId) {
                                            navigate(
                                                `/texts/${textseo}/${bookseo}`
                                            )
                                        }
                                    }}
                                >
                                    <Td>{bookname}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </HStack>
    )
}

export default SelectBook
