import React, { createContext, ReactNode, useContext } from 'react'
import { Session } from '@supabase/supabase-js'
import { supabase } from '../supabaseClient'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const version = require('../../package.json').version

export enum Roles {
    Undefined = -1,
    Admin = 1,
    Regular = 2,
}

export type RoleType = Roles.Admin | Roles.Regular | Roles.Undefined

export const AuthContext = createContext<Session | null>(null)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [session, setSession] = React.useState<Session | null>(null)

    React.useEffect(() => {
        const versionKey = 'appVersion'
        const storedVersion = localStorage.getItem(versionKey)

        if (!storedVersion || storedVersion !== version) {
            console.log(
                'App version changed or not set. Clearing localStorage and updating version...'
            )
            localStorage.clear() // Clear localStorage if version is outdated or not set
            localStorage.setItem(versionKey, version) // Update to current version
        }
    }, [])

    React.useEffect(() => {
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((event, session) => {
            console.log(event, session)
            if (event === 'SIGNED_OUT') {
                setSession(null)
            } else if (session) {
                setSession(session)
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    return (
        <AuthContext.Provider value={session}>{children}</AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}
