import {
    HStack,
    VStack,
    Heading,
    TableContainer,
    Table,
    TableCaption,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from '@chakra-ui/react'
import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../supabaseClient'
import { IEncodingPendingApprovalEntry } from '../interfaces/IEncodingPendingApprovalEntry'

const Approve = () => {
    const navigate = useNavigate()

    const [encodingsPendingApproval, setEncodingsPendingApproval] = useState<
        IEncodingPendingApprovalEntry[]
    >([] as IEncodingPendingApprovalEntry[])

    const getEncodingsPendingApproval = useCallback(async () => {
        const { data } = await supabase
            .from('etcbc_encoding_pendingapproval')
            .select(
                '*, user_id(*), encoding (*, verse(*, text(*), verse(*, book_name(*))))'
            )

        if (data) {
            setEncodingsPendingApproval(data as IEncodingPendingApprovalEntry[])
        }
    }, [])

    useEffect(() => {
        getEncodingsPendingApproval()
    }, [getEncodingsPendingApproval])

    useEffect(() => {
        const channel = supabase.channel('etcbc_encoding_pendingapproval')

        channel
            .on(
                'postgres_changes',
                {
                    event: 'INSERT',
                    schema: 'public',
                    table: 'etcbc_encoding_pendingapproval',
                },
                () => getEncodingsPendingApproval()
            )
            .on(
                'postgres_changes',
                {
                    event: 'DELETE',
                    schema: 'public',
                    table: 'etcbc_encoding_pendingapproval',
                },
                () => getEncodingsPendingApproval()
            )
            .subscribe()

        return () => {
            supabase.removeChannel(channel)
        }
    }, [getEncodingsPendingApproval])

    return (
        <HStack alignItems="flex-start" width="100%">
            <VStack
                spacing="8"
                background="white"
                padding="24px"
                borderRadius="24px"
                minWidth={'container.md'}
                width="100%"
            >
                <Heading textAlign="center" color={'twitter.800'}>
                    Pending approval
                </Heading>
                <TableContainer width="100%">
                    <Table variant="simple" size="sm">
                        <TableCaption>Current online corpus</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Text</Th>
                                <Th>Book</Th>
                                <Th>Chapter</Th>
                                <Th>Verse</Th>
                                <Th>Created by</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {encodingsPendingApproval.map(
                                (encodingPendingApproval) => (
                                    <Tr
                                        key={`encodingsPendingApproval-${encodingPendingApproval.id}`}
                                        _hover={{
                                            backgroundColor:
                                                'var(--chakra-colors-gray-100)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            navigate(
                                                `/texts/${encodingPendingApproval.encoding.verse.text.seo}/${encodingPendingApproval.encoding.verse.verse.book_name.seo}/${encodingPendingApproval.encoding.verse.verse.chapter}?showEncoding=true&reviewId=${encodingPendingApproval.encoding.verse.verse.id}`
                                            )
                                        }}
                                    >
                                        <Td>
                                            {
                                                encodingPendingApproval.encoding
                                                    .verse.text.title
                                            }
                                        </Td>
                                        <Td>
                                            {
                                                encodingPendingApproval.encoding
                                                    .verse.verse.book_name.name
                                            }
                                        </Td>
                                        <Td>
                                            {
                                                encodingPendingApproval.encoding
                                                    .verse.verse.chapter
                                            }
                                        </Td>
                                        <Td>
                                            {
                                                encodingPendingApproval.encoding
                                                    .verse.verse.number
                                            }
                                        </Td>
                                        <Td>
                                            {
                                                encodingPendingApproval.user_id
                                                    .first_name
                                            }{' '}
                                            {
                                                encodingPendingApproval.user_id
                                                    .last_name
                                            }
                                        </Td>
                                    </Tr>
                                )
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </HStack>
    )
}

export default Approve
