import React from 'react'
import { Icon } from '@chakra-ui/react'
import { HiChevronRight } from 'react-icons/hi'

function ChevronRight() {
    return (
        <Icon
            alignItems={'center'}
            display="flex"
            boxSize="5"
            as={HiChevronRight}
            color="gray"
        />
    )
}

export default ChevronRight
