import { Button, Tag } from '@chakra-ui/react'
import styled from '@emotion/styled'

const NavButton = styled(Button)`
    > span:first-of-type {
        top: -2px;
        position: relative;
    }

    > span:nth-of-type(2) {
        position: absolute;
        left: 0rem;
        top: 0rem;
    }
`

export { NavButton }
