import { ChakraProps, Icon } from "@chakra-ui/react";

const Logo = (props: ChakraProps) => (
  <Icon viewBox="0 0 344.83 200" {...props}>
    <path d="M238.29,60.5c.43.75,1.21.2,1.82,0,17.63-4.33,35.28-3.19,52.79.63A137.45,137.45,0,0,1,330.16,75c1.78,1,17.19,25.73,14.09,27.23,3.3-1.6-8.7,13.45-8.7,13.45s-32.6,20.15-43.92,27.05c-17.57,10.7-33.75,24.14-48.81,39.8-12.32,12.81-23.74,26.77-32.37,44A139.48,139.48,0,0,0,200.8,252c-1.21,4.35-1.47,4.29-4.7,2.06-12.4-8.52-25-16.45-38.2-22.84-13.93-6.73-23.45-11.78-38.09-14.92-15.2-3.25-35.22-4.19-50.65-2.93a134.32,134.32,0,0,0-38.72,8.75,113.7,113.7,0,0,0-26,14.93A10.12,10.12,0,0,1,3,238.14c-.87.44-1.66,2.15-2.66,1-.77-.92.1-2.2.32-3.3a207.12,207.12,0,0,1,12.73-40.33c7.41-17.19,16.43-32.88,28-45.88,4.18-4.69,6.38-9.67,5.77-16.89-.53-6.2.38-12.51-.47-18.74a1.72,1.72,0,0,1,.75-2c2.28,3.58,4,7.72,6.35,11.32a118.47,118.47,0,0,0,20,23.3c10.93,10,23.25,16,35.85,21.29,12.41,5.21,24.77,10.57,36.61,17.67S169,200.89,178.69,212c2.88,3.3,17.34,24.54,19.75,20.76,9.65-15.2,25.36-61.86,91.54-113.88,16.93-13.31,21.44-18.13,24.3-19.49a14.89,14.89,0,0,0-4.2-4C289.54,82,267.91,74.89,244.72,76.86c-3.78.32-7.55,1-11.32,1.51a6.15,6.15,0,0,0-4.6,3.52c-3.66,6.69-6.74,13.84-10.06,20.8-8.18,17.15-42.19,87.55-43.51,85.56-1.81-2.74,1.24-6.68,5.27-16.78,8.15-20.41,32.42-92.35,35-97.14a4.37,4.37,0,0,0-.45-5.49,75.72,75.72,0,0,0-21.72-18.79c-13-7.81-16.66-9.44-23.82-10.9-1.29-.27-5.2-18.32-4.21-19.25s.74-.47,10.25,2.39a89.68,89.68,0,0,1,24.65,11.78,78.27,78.27,0,0,1,23.59,24.3" />
    <path d="M160,51c-2.62-10.54.79-23.4-6.52-31.85C140.45,3.48,128.22-.75,102,.1c-44.69,1.46-70.16,71-52.62,100.43,15.62,36.92,61.57,58.6,98.14,40,11.09-7.15,3.77-26.7,5.77-38.3C156.5,85.12,163.45,69.59,160,51Z" />
  </Icon>
);

export default Logo;
