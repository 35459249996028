import { HStack, VStack } from '@chakra-ui/react'
import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRef } from 'react'
import WordLevelAnalysis, { IWordLevelAnalysis } from './WordLevelAnalysis'
import {
    IVerse,
    ITextInfo,
    ReaderContext,
    H2,
    VerseTextContainer,
    ReaderActions,
    HebrewParagraph,
    GreekParagraph,
    CommentaryText,
    IWLAStore,
} from '../../pages/Reader'
import { Encoder } from './Encoder'

export const Verse: React.FC<{
    verse: IVerse
    displayEncoding: boolean
    enableWesternScript: boolean
    mainTextInfo: ITextInfo
    chapterId: string | undefined
    wlaStore: IWLAStore
    qoroyoIdentifier: string
}> = ({
    verse,
    displayEncoding,
    enableWesternScript,
    mainTextInfo,
    chapterId,
    wlaStore,
    qoroyoIdentifier,
}) => {
    const [searchParams] = useSearchParams()
    const verseRef = useRef<HTMLDivElement | null>(null)
    const hiddenInputRef = useRef<HTMLInputElement | null>(null)

    const { dispatch: readerDispatch } = useContext(ReaderContext)

    useEffect(() => {
        if (searchParams.get('reviewId') === verse.id) {
            verseRef?.current?.scrollIntoView()
        }
    }, [searchParams, verse.id])

    return (
        <VStack key={verse.id} width="100%" ref={verseRef} spacing="5">
            <H2
                style={{
                    color: '#aec5eb',
                    width: '100%',
                    fontSize: '1.25rem',
                }}
            >
                {chapterId}:{`${verse.number}`}
            </H2>
            <input type="hidden" ref={hiddenInputRef} />
            {verse.verses.some((v) => v.text.id === mainTextInfo.textId) &&
                verse.verses.map((childVerse) => {
                    const secondaryText =
                        childVerse.text.id !== mainTextInfo.textId

                    const encoding = childVerse.text.encoding

                    return (
                        <VStack width="100%" key={childVerse.id}>
                            {secondaryText && (
                                <HStack width="100%">
                                    <span>{childVerse.text.title}:</span>
                                </HStack>
                            )}
                            <HStack width="100%">
                                <VerseTextContainer
                                    width="100%"
                                    display={'flow-root'}
                                    style={{
                                        borderRadius: !secondaryText
                                            ? '1rem'
                                            : 0,
                                        textAlign:
                                            encoding === 'htmlenglish'
                                                ? 'left'
                                                : 'right',
                                        direction:
                                            encoding === 'htmlenglish'
                                                ? 'ltr'
                                                : 'rtl',
                                    }}
                                    onTouchEnd={(event) => {
                                        if (encoding !== 'syriacetcbc') {
                                            return
                                        }
                                        event.preventDefault()
                                        const selectedText = window
                                            .getSelection()
                                            ?.toString()

                                        if (selectedText) {
                                            hiddenInputRef.current?.focus()
                                            hiddenInputRef.current?.blur()
                                            window
                                                .getSelection()
                                                ?.removeAllRanges()
                                            readerDispatch({
                                                type: ReaderActions.LOOKUP_WORD,
                                                dispatch: {
                                                    word: selectedText,
                                                    timestamp:
                                                        new Date().toString(),
                                                },
                                            })
                                        }
                                    }}
                                    onDoubleClick={() => {
                                        if (encoding !== 'syriacetcbc') {
                                            return
                                        }

                                        const selectedText = window
                                            .getSelection()
                                            ?.toString()

                                        if (selectedText) {
                                            readerDispatch({
                                                type: ReaderActions.LOOKUP_WORD,
                                                dispatch: {
                                                    word: selectedText,
                                                    timestamp:
                                                        new Date().toString(),
                                                },
                                            })
                                        }
                                    }}
                                >
                                    {encoding === 'syriacetcbc' && (
                                        <WordLevelAnalysis
                                            enableWesternScript={
                                                enableWesternScript
                                            }
                                            text={childVerse.body}
                                            wla={
                                                wlaStore[qoroyoIdentifier] &&
                                                wlaStore[qoroyoIdentifier][
                                                    childVerse.id
                                                ]
                                            }
                                        />
                                    )}
                                    {(encoding === 'aramaic' ||
                                        encoding === 'hebrew') && (
                                        <>
                                            <HebrewParagraph
                                                style={{ width: '100%' }}
                                                direction="right"
                                            >
                                                {childVerse.body}
                                            </HebrewParagraph>
                                        </>
                                    )}
                                    {encoding === 'greek' && (
                                        <>
                                            <GreekParagraph
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'left',
                                                    wordBreak: 'normal',
                                                }}
                                                direction="left"
                                            >
                                                {childVerse.body}
                                            </GreekParagraph>
                                        </>
                                    )}
                                    {encoding === 'htmlenglish' && (
                                        <>
                                            <CommentaryText
                                                style={{ width: '100%' }}
                                                dangerouslySetInnerHTML={{
                                                    __html: childVerse.body,
                                                }}
                                            ></CommentaryText>
                                        </>
                                    )}
                                </VerseTextContainer>
                            </HStack>
                            {displayEncoding &&
                            verse.encoding &&
                            !secondaryText ? (
                                <Encoder
                                    encoding={verse.encoding}
                                    suggestedChange={
                                        verse.encoding.suggestedChange
                                    }
                                    westernScript={enableWesternScript}
                                />
                            ) : null}
                        </VStack>
                    )
                })}
        </VStack>
    )
}
