import {
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Link,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { supabase } from '../supabaseClient'

interface ISignupForm {
    email: string
    password: string
    confirmPassword: string
    firstName: string
    lastName: string
    api: string
}

const validatePassword = (values: string) => {
    let error = ''
    const passwordRegex = /(?=.*[0-9])/
    if (!values) {
        error = '*Required'
    } else if (values.length < 8) {
        error = '*Password must be 8 characters long.'
    } else if (!passwordRegex.test(values)) {
        error = '*Invalid password. Must contain one number.'
    }
    return error
}

const validateConfirmPassword = (pass: any, value: any) => {
    let error = ''
    if (pass && value) {
        if (pass !== value) {
            error = 'Password not matched'
        }
    }
    return error
}

const Signup = () => {
    const navigate = useNavigate()

    const [params] = useSearchParams()
    const initialValues: ISignupForm = {
        email: params.get('email') || '',
        password: '',
        api: '',
        firstName: '',
        lastName: '',
        confirmPassword: '',
    }

    const verifyOtp = useCallback(async () => {
        const email = params.get('email') || ''
        const oneTimePassword = params.get('otp') || ''

        const { data, error } = await supabase.auth.verifyOtp({
            email,
            token: oneTimePassword,
            type: 'invite',
        })

        return { data, error }
    }, [params])

    function validate(value: string) {
        let error
        if (!value) {
            error = 'Required'
        }

        return error
    }

    return (
        <Flex
            justifyContent="center"
            alignItems={{
                base: 'stretch',
                md: 'center',
            }}
            minWidth="100vw"
            minHeight="100vh"
            background="gray.200"
            padding={{
                base: '8px',
                md: 0,
            }}
        >
            <Stack
                flexDir="column"
                mb={{
                    base: 0,
                    md: '2',
                }}
                background="white"
                padding="48px 24px"
                borderRadius={{
                    base: '8px',
                    md: '16px',
                }}
                justifyContent={{
                    base: 'flex-start',
                    md: 'center',
                }}
                minW={{
                    base: '100%',
                    md: 'auto',
                }}
                minH={{
                    base: '100%',
                    md: 'auto',
                }}
            >
                <Heading>Signup</Heading>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={async (values, actions) => {
                        try {
                            const { error: otpError } = await verifyOtp()
                            const { data, error } =
                                await supabase.auth.updateUser({
                                    password: values.password,
                                })

                            if (error) throw error
                            if (otpError) throw otpError

                            const { error: profileError } = await supabase
                                .from('profiles')
                                .insert({
                                    first_name: values.firstName,
                                    last_name: values.lastName,
                                    id: data.user.id,
                                })

                            if (profileError) throw profileError

                            navigate('/')
                        } catch (error: any) {
                            actions.setErrors({ api: error.message })
                        }
                    }}
                >
                    {({ isSubmitting, errors, values }) => (
                        <Form>
                            <VStack spacing="16px">
                                <Field name="firstName" validate={validate}>
                                    {({
                                        field,
                                        form,
                                    }: FieldProps<any, ISignupForm>) => (
                                        <FormControl
                                            isInvalid={
                                                (form.errors.firstName &&
                                                    form.touched
                                                        .firstName) as boolean
                                            }
                                        >
                                            <FormLabel>First name</FormLabel>
                                            <Input
                                                {...field}
                                                placeholder="First name"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.firstName}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="lastName" validate={validate}>
                                    {({
                                        field,
                                        form,
                                    }: FieldProps<any, ISignupForm>) => (
                                        <FormControl
                                            isInvalid={
                                                (form.errors.lastName &&
                                                    form.touched
                                                        .lastName) as boolean
                                            }
                                        >
                                            <FormLabel>Last name</FormLabel>
                                            <Input
                                                {...field}
                                                placeholder="Last name"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.lastName}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Divider />
                                <Field
                                    name="password"
                                    validate={validatePassword}
                                >
                                    {({
                                        field,
                                        form,
                                    }: FieldProps<any, ISignupForm>) => (
                                        <FormControl
                                            isInvalid={
                                                (form.errors.password &&
                                                    form.touched
                                                        .password) as boolean
                                            }
                                        >
                                            <FormLabel>Password</FormLabel>
                                            <Input
                                                type="password"
                                                {...field}
                                                placeholder="Password"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.password}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field
                                    name="confirmPassword"
                                    validate={(value: any) =>
                                        validateConfirmPassword(
                                            values.password,
                                            value
                                        )
                                    }
                                >
                                    {({
                                        field,
                                        form,
                                    }: FieldProps<any, ISignupForm>) => (
                                        <FormControl
                                            isInvalid={
                                                (form.errors.confirmPassword &&
                                                    form.touched
                                                        .confirmPassword) as boolean
                                            }
                                        >
                                            <FormLabel>
                                                Confirm password
                                            </FormLabel>
                                            <Input
                                                type="password"
                                                {...field}
                                                placeholder="Confirm password"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.confirmPassword}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {errors.api && (
                                    <Text color="red">{errors.api}</Text>
                                )}
                                <Button
                                    mt={4}
                                    colorScheme="teal"
                                    isLoading={isSubmitting}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </VStack>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </Flex>
    )
}

export default Signup
