import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Grid,
    Heading,
    HStack,
    Spinner,
    Tag,
    useToast,
    VStack,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ChevronRight from '../icons/ChevronRight'
import { supabase } from '../supabaseClient'

interface IChapterEntry {
    texttitle: string
    bookname: string
    textseo: string
    bookseo: string
    chapter: number
}

const SelectChapter = () => {
    const { textId, bookId } = useParams()
    const toast = useToast()
    const navigate = useNavigate()
    const toastId = 'error-chapters-toast'

    const [loading, setLoading] = useState(false)
    const [chaptersList, setChapters] = useState([] as IChapterEntry[])

    const loadData = useCallback(async () => {
        setLoading(true)

        const { data, error } = await supabase.rpc('get_distinct_chapters', {
            bookid: bookId,
            textid: textId,
        })

        setLoading(false)

        if (error && !toast.isActive(toastId)) {
            console.log(error)
            toast({
                id: toastId,
                title: 'Issue',
                description: 'Could not load chapters',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
            })
        }

        if (data) {
            setChapters(data)
        }
    }, [textId, bookId, toast])

    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <HStack alignItems="flex-start" width="100%">
            <VStack
                spacing="8"
                background="white"
                padding={{
                    base: '16px 8px',
                    md: '24px',
                }}
                borderRadius={{
                    base: '8px',
                    md: '24px',
                }}
                minWidth={{
                    base: '100%',
                    md: 'container.md',
                }}
                width="100%"
                minH={'30rem'}
            >
                <Heading
                    textAlign="center"
                    justifyItems="center"
                    color={'twitter.800'}
                >
                    Chapters
                </Heading>
                <Breadcrumb spacing="8px" separator={<ChevronRight />}>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to="/texts">
                            Texts
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {!loading ? (
                            <BreadcrumbLink as={Link} to={`/texts/${textId}`}>
                                {chaptersList[0]?.texttitle}
                            </BreadcrumbLink>
                        ) : (
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="teal.500"
                                size="sm"
                            />
                        )}
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {!loading ? (
                            <Tag _hover={{ cursor: 'default' }}>
                                {chaptersList[0]?.bookname}
                            </Tag>
                        ) : (
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="teal.500"
                                size="sm"
                            />
                        )}
                    </BreadcrumbItem>
                </Breadcrumb>
                <Grid
                    templateColumns={{
                        base: 'repeat(4, 1fr)',
                        md: 'repeat(10, 1fr)',
                    }}
                    gap="6"
                    width="100%"
                    justifyItems={'center'}
                >
                    {loading && (
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="teal.500"
                            size="md"
                        />
                    )}
                    {chaptersList.map(({ bookseo, textseo, chapter }) => (
                        <Button
                            key={`texts-${textseo}-${bookseo}-${chapter}`}
                            colorScheme="gray"
                            variant="outline"
                            size="sm"
                            width="52px"
                            onClick={() => {
                                navigate(
                                    `/texts/${textseo}/${bookseo}/${chapter}`
                                )
                            }}
                        >
                            {chapter}
                        </Button>
                    ))}
                </Grid>
            </VStack>
        </HStack>
    )
}

export default SelectChapter
