import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    Select,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { supabase } from '../supabaseClient'

interface IInviteForm {
    email: string
    role: string
    api: string
}

const Invite = () => {
    const initialValues: IInviteForm = { email: '', api: '', role: '2' }
    const toast = useToast()

    function validate(value: string) {
        let error
        if (!value) {
            error = 'Email is required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Not a valid email'
        } else {
            error = null
        }

        return error
    }

    return (
        <HStack alignItems="flex-start" width="100%">
            <VStack
                spacing="5"
                background="white"
                padding="24px"
                borderRadius="24px"
                minWidth={'container.md'}
                width="100%"
            >
                <Heading textAlign="center" color={'twitter.800'}>
                    Invite new user
                </Heading>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        const toastId = 'toast-invite-user'

                        const { data, error } =
                            await supabase.auth.admin.inviteUserByEmail(
                                values.email
                            )
                        const { error: roleError } = await supabase
                            .from('roles')
                            .insert({
                                email: values.email,
                                role: values.role,
                            })

                        const succesfulPost = data

                        if (succesfulPost && !toast.isActive(toastId)) {
                            toast({
                                id: toastId,
                                title: 'Success',
                                description: 'User has been invited',
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                                variant: 'subtle',
                                position: 'bottom',
                            })
                        }

                        const errorOccurred = error || roleError

                        if (errorOccurred && !toast.isActive(toastId)) {
                            toast({
                                id: toastId,
                                title: 'Issue',
                                description: 'Could not invite user',
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                                variant: 'subtle',
                                position: 'bottom',
                            })
                        }
                    }}
                >
                    {({ isSubmitting, errors }) => (
                        <Form>
                            <VStack spacing="16px">
                                <Field name="email" validate={validate}>
                                    {({
                                        field,
                                        form,
                                    }: FieldProps<
                                        | string
                                        | number
                                        | readonly string[]
                                        | undefined,
                                        IInviteForm
                                    >) => (
                                        <FormControl
                                            isInvalid={
                                                (form.errors.email &&
                                                    form.touched
                                                        .email) as boolean
                                            }
                                        >
                                            <FormLabel>Email</FormLabel>
                                            <Input
                                                {...field}
                                                placeholder="email"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.email}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {errors.api && (
                                    <Text color="red">{errors.api}</Text>
                                )}
                                <Field name="role">
                                    {({
                                        field,
                                    }: FieldProps<
                                        | string
                                        | number
                                        | readonly string[]
                                        | undefined,
                                        IInviteForm
                                    >) => (
                                        <FormControl>
                                            <FormLabel>Role</FormLabel>
                                            <Select
                                                defaultValue={'2'}
                                                {...field}
                                            >
                                                <option value="2">
                                                    Regular
                                                </option>
                                                <option value="1">
                                                    Administrator
                                                </option>
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                                <Button
                                    mt={4}
                                    colorScheme="teal"
                                    isLoading={isSubmitting}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </VStack>
                        </Form>
                    )}
                </Formik>
            </VStack>
        </HStack>
    )
}

export default Invite
