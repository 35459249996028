import {
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    Link,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Logo from '../components/Logo'
import { supabase } from '../supabaseClient'

interface IForgotPassword {
    email: string
    password: string
    confirmPassword: string
    api: string
}

const validatePassword = (values: string) => {
    let error = ''
    const passwordRegex = /(?=.*[0-9])/
    if (!values) {
        error = '*Required'
    } else if (values.length < 8) {
        error = '*Password must be 8 characters long.'
    } else if (!passwordRegex.test(values)) {
        error = '*Invalid password. Must contain one number.'
    }
    return error
}

const validateConfirmPassword = (pass: any, value: any) => {
    let error = ''
    if (pass && value) {
        if (pass !== value) {
            error = 'Password not matched'
        }
    }
    return error
}

const ForgotPassword = () => {
    const navigate = useNavigate()

    const [params] = useSearchParams()
    const initialValues: IForgotPassword = {
        email: params.get('email') || '',
        password: '',
        api: '',
        confirmPassword: '',
    }

    const verifyOtp = useCallback(async () => {
        const email = params.get('email') || ''
        const oneTimePassword = params.get('otp') || ''

        const { data, error } = await supabase.auth.verifyOtp({
            email,
            token: oneTimePassword,
            type: 'recovery',
        })

        return { data, error }
    }, [params])

    return (
        <Flex
            justifyContent="center"
            minWidth="100vw"
            minHeight="100vh"
            background="gray.200"
            alignItems={{
                base: 'stretch',
                md: 'center',
            }}
            padding={{
                base: '8px',
                md: 0,
            }}
        >
            <VStack spacing={3} w={{ base: '100%', md: 'auto' }}>
                <VStack
                    flexDir="column"
                    mb={{
                        base: 0,
                        md: '2',
                    }}
                    padding="48px 24px"
                    background="white"
                    borderRadius={{
                        base: '8px',
                        md: '16px',
                    }}
                    justifyContent={{
                        base: 'flex-start',
                        md: 'center',
                    }}
                    minW={{
                        base: '100%',
                        md: 'auto',
                    }}
                    minH={{
                        base: '100%',
                        md: 'auto',
                    }}
                    alignItems="center"
                    spacing={10}
                >
                    <Heading color={'twitter.800'}>Reset password</Heading>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={async (values, actions) => {
                            try {
                                const { error: otpError } = await verifyOtp()
                                const { data, error } =
                                    await supabase.auth.updateUser({
                                        password: values.password,
                                    })

                                if (error) throw error
                                if (otpError) throw otpError

                                navigate('/')
                            } catch (error: any) {
                                actions.setErrors({ api: error.message })
                            }
                        }}
                    >
                        {({ isSubmitting, errors, values }) => (
                            <Form>
                                <VStack spacing="16px">
                                    <Field
                                        name="password"
                                        validate={validatePassword}
                                    >
                                        {({
                                            field,
                                            form,
                                        }: FieldProps<
                                            any,
                                            IForgotPassword
                                        >) => (
                                            <FormControl
                                                isInvalid={
                                                    (form.errors.password &&
                                                        form.touched
                                                            .password) as boolean
                                                }
                                            >
                                                <FormLabel>
                                                    New password
                                                </FormLabel>
                                                <Input
                                                    type="password"
                                                    {...field}
                                                    placeholder="Password"
                                                />
                                                <FormErrorMessage>
                                                    {form.errors.password}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field
                                        name="confirmPassword"
                                        validate={(value: any) =>
                                            validateConfirmPassword(
                                                values.password,
                                                value
                                            )
                                        }
                                    >
                                        {({
                                            field,
                                            form,
                                        }: FieldProps<
                                            any,
                                            IForgotPassword
                                        >) => (
                                            <FormControl
                                                isInvalid={
                                                    (form.errors
                                                        .confirmPassword &&
                                                        form.touched
                                                            .confirmPassword) as boolean
                                                }
                                            >
                                                <FormLabel>
                                                    Confirm password
                                                </FormLabel>
                                                <Input
                                                    type="password"
                                                    {...field}
                                                    placeholder="Confirm password"
                                                />
                                                <FormErrorMessage>
                                                    {
                                                        form.errors
                                                            .confirmPassword
                                                    }
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    {errors.api && (
                                        <Text color="red">{errors.api}</Text>
                                    )}
                                    <Button
                                        mt={4}
                                        colorScheme="teal"
                                        isLoading={isSubmitting}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </VStack>
                <HStack
                    justifyContent="center"
                    w={'100%'}
                    display={{ base: 'none', md: 'flex' }}
                >
                    <Heading color="teal" fontSize={'3xl'}>
                        Qoroyo
                    </Heading>
                </HStack>
            </VStack>
        </Flex>
    )
}

export default ForgotPassword
