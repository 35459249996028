import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useState,
} from 'react'
import { supabase } from '../supabaseClient'
import { RoleType, useAuth } from './Auth'

interface UserProfile {
    id: string
    first_name: string
    last_name: string
}

export const ProfileContext = createContext<{
    role: RoleType
    profile: UserProfile
    resetProfile: () => void
}>({
    role: -1,
    profile: {} as UserProfile,
    resetProfile: () => {
        return
    },
})

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
    const session = useAuth()
    const [role, setRole] = useState<RoleType>(-1)
    const [profile, setProfile] = useState<UserProfile>({} as UserProfile)

    const resetProfile = useCallback(() => {
        setProfile({} as UserProfile)
    }, [])

    const getProfileInfo = useCallback(async () => {
        const roleResponse = await supabase
            .from('roles')
            .select()
            .eq('email', session?.user.email)
            .limit(1)
            .single()
        const profileResponse = await supabase
            .from('profiles')
            .select()
            .eq('id', session?.user.id)
            .limit(1)
            .single()

        setRole(roleResponse.data.role)
        setProfile(profileResponse.data)
    }, [session])

    React.useEffect(() => {
        if (session?.user) {
            getProfileInfo()
        }
    }, [getProfileInfo, session?.user])

    return (
        <ProfileContext.Provider value={{ role, profile, resetProfile }}>
            {children}
        </ProfileContext.Provider>
    )
}

export function useProfile() {
    return useContext(ProfileContext)
}
