import { Icon } from '@chakra-ui/react'
import { HiChevronLeft } from 'react-icons/hi'

function ChevronLeft() {
    return (
        <Icon
            alignItems={'center'}
            display="flex"
            boxSize="5"
            as={HiChevronLeft}
            color="gray"
        />
    )
}

export default ChevronLeft
