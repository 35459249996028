import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Divider,
    Heading,
    HStack,
    Tag,
    VStack,
    Text,
    Spinner,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useDisclosure,
    useMediaQuery,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ReaderActions, ReaderContext } from '../pages/Reader'

interface ISedraResponse {
    word: {
        id: string
        uri: string
    }
    lexeme: {
        id: string
        uri: string
    }
    glosses: {
        [index: string]: string[]
    }
    syriac: string
    western: string
    eastern: string
    state: string
    tense: string
    kaylo: string
    number: string
    person: string
    gender: string
    suffixType: string
    suffixNumber: string
    suffixPerson: string
    suffixGender: string
    hasSeyame: boolean
    isLexicalForm: boolean
    isEnclitic: boolean
    isTheoretical: boolean
}

const LookupContainer = styled(VStack)`
    width: 100%;

    ul {
        list-style-type: none;
    }
`

const Lookup: React.FC<{ word: string; timestamp: string }> = ({
    word,
    timestamp,
}) => {
    const [sedraResults, setSedraResults] = useState<ISedraResponse[]>()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLargerThanSm] = useMediaQuery('(min-width: 768px)')

    const { state: readerState, dispatch: readerDispatch } =
        useContext(ReaderContext)

    const getWordData = useCallback(
        async (word: string) => {
            readerDispatch({
                type: ReaderActions.SET_LOOKINGUP_WORD,
                dispatch: true,
            })
            try {
                const res = await axios.get<ISedraResponse[]>(
                    `https://sedra.bethmardutho.org/api/word/${word}`
                )

                setSedraResults(res.data)
                readerDispatch({
                    type: ReaderActions.SET_LOOKINGUP_WORD,
                    dispatch: false,
                })
            } catch (err) {
                setSedraResults([])
                readerDispatch({
                    type: ReaderActions.SET_LOOKINGUP_WORD,
                    dispatch: false,
                })
            }
        },
        [readerDispatch]
    )

    useEffect(() => {
        if (word.length) {
            getWordData(word)
            onOpen()
        }
    }, [getWordData, word, onOpen, timestamp])

    const LookupInfo = () => (
        <VStack
            as="ul"
            spacing="3"
            justifyContent={'flex-start'}
            alignItems="flex-start"
            listStyleType="none"
        >
            <li>
                <Heading color={'twitter.800'} fontSize="2xl">
                    SEDRA Linguistic analysis
                </Heading>
            </li>
            <Divider m={'16px 0'} />
            {readerState.lookingupWord && (
                <HStack w="100%" justifyContent={'center'}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="teal.500"
                        size="md"
                    />
                </HStack>
            )}
            {!readerState.lookingupWord && sedraResults === undefined && (
                <Text fontSize={'sm'} color="gray.500">
                    Double click on a Syriac word to begin analysis.
                </Text>
            )}
            {sedraResults?.length === 0 && (
                <Text fontSize={'sm'} color="gray.500">
                    No results found for selected word.
                </Text>
            )}
            {sedraResults?.map((result) => (
                <VStack as="li" w="100%">
                    <VStack as="ul" alignItems={'flex-start'} w="100%">
                        {result.western && (
                            <HStack
                                as="li"
                                spacing={3}
                                w="100%"
                                justifyContent="space-between"
                            >
                                <Tag>Western: </Tag>
                                <Text
                                    lineHeight="1"
                                    fontFamily={'SertoJerusalem'}
                                    fontSize="2xl"
                                >
                                    {result.western}
                                </Text>
                            </HStack>
                        )}
                        {result.kaylo && (
                            <HStack
                                as="li"
                                spacing={3}
                                w="100%"
                                justifyContent="space-between"
                            >
                                <Tag>Form: </Tag>
                                <Text fontSize="sm">{result.kaylo}</Text>
                            </HStack>
                        )}
                        {result.state && (
                            <HStack
                                as="li"
                                spacing={3}
                                w="100%"
                                justifyContent="space-between"
                            >
                                <Tag>State: </Tag>
                                <Text fontSize="sm">{result.state}</Text>
                            </HStack>
                        )}
                        {result.gender && (
                            <HStack
                                as="li"
                                spacing={3}
                                w="100%"
                                justifyContent="space-between"
                            >
                                <Tag>Gender: </Tag>{' '}
                                <Text fontSize="sm">{result.gender}</Text>
                            </HStack>
                        )}
                        {result.person && (
                            <HStack
                                as="li"
                                spacing={3}
                                w="100%"
                                justifyContent="space-between"
                            >
                                <Tag>Person: </Tag>{' '}
                                <Text fontSize="sm">{result.person}</Text>
                            </HStack>
                        )}
                        {result.number && (
                            <HStack
                                as="li"
                                spacing={3}
                                w="100%"
                                justifyContent="space-between"
                            >
                                <Tag>Number: </Tag>{' '}
                                <Text fontSize="sm">{result.number}</Text>
                            </HStack>
                        )}
                        {result.tense && (
                            <HStack
                                as="li"
                                spacing={3}
                                w="100%"
                                justifyContent="space-between"
                            >
                                <Tag>Tense: </Tag>{' '}
                                <Text fontSize="sm">{result.tense}</Text>
                            </HStack>
                        )}
                        <Accordion allowToggle m="16px 0" w={'100%'}>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                        >
                                            Glosses
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                {Object.keys(result.glosses).map(
                                    (key) =>
                                        result.glosses[key].length && (
                                            <AccordionPanel pb={4}>
                                                <ul>
                                                    <li>{key}: </li>
                                                    <VStack
                                                        as="ul"
                                                        display="flex"
                                                        alignItems="flex-start"
                                                        style={{
                                                            overflowY: 'auto',
                                                        }}
                                                    >
                                                        {result.glosses[
                                                            key
                                                        ].map((gloss) => (
                                                            <Tag
                                                                padding="2"
                                                                as="li"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: gloss,
                                                                }}
                                                                colorScheme="pink"
                                                            />
                                                        ))}
                                                    </VStack>
                                                </ul>
                                            </AccordionPanel>
                                        )
                                )}
                            </AccordionItem>
                        </Accordion>
                    </VStack>
                </VStack>
            ))}
            <Text
                fontSize={'xs'}
                color="gray.500"
                textAlign={'center'}
                w="100%"
            >
                Powered by Sedra IV
            </Text>
        </VStack>
    )
    return (
        <>
            {!isLargerThanSm && (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton />
                        <ModalBody>
                            <LookupInfo />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
            <LookupContainer
                display={{
                    base: 'none',
                    md: 'flex',
                }}
                background="white"
                padding="24px"
                borderRadius="24px"
                maxWidth={'238px'}
            >
                <LookupInfo />
            </LookupContainer>
        </>
    )
}

export default Lookup
