import { Button, HStack, IconButton, Text } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

import React, { useState } from 'react'

const TruncateText: React.FC<{ text: string; maxLength: number }> = ({
    text,
    maxLength,
}) => {
    const [isTruncated, setIsTruncated] = useState(true)

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated)
    }

    return (
        <>
            {isTruncated ? (
                <HStack w="100%">
                    <Text fontSize="sm">
                        {text.length > maxLength
                            ? `${text.substring(0, maxLength)}...`
                            : text}
                    </Text>
                    {text.length > maxLength && (
                        <IconButton
                            onClick={toggleTruncate}
                            aria-label="expand"
                            size="xs"
                            variant="outline"
                            icon={<ChevronDownIcon />}
                        />
                    )}
                </HStack>
            ) : (
                <HStack>
                    <Text fontSize="sm">{text}</Text>
                    {text.length > maxLength && (
                        <IconButton
                            onClick={toggleTruncate}
                            aria-label="expand"
                            size="xs"
                            variant="outline"
                            icon={<ChevronUpIcon />}
                        />
                    )}
                </HStack>
            )}
        </>
    )
}

export default TruncateText
