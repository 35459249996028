import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../supabaseClient'

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const navigate = useNavigate()

    const getSession = useCallback(async () => {
        const { data } = await supabase.auth.getSession()

        if (!data.session) {
            navigate('/login', { replace: true })
        }
    }, [navigate])

    useEffect(() => {
        getSession()
    }, [getSession])

    return children
}

export default PrivateRoute
