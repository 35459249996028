import {
    Heading,
    HStack,
    Spinner,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { supabase } from '../supabaseClient'
import { ITextInfo } from '../interfaces/ITextInfo'

const Texts = () => {
    const navigate = useNavigate()
    const toast = useToast()

    const [loading, setLoading] = useState(false)
    const [texts, setTexts] = useState<ITextInfo[]>([] as ITextInfo[])

    const loadTexts = useCallback(async () => {
        const toastId = 'error-texts-toast'
        setLoading(true)

        const { data, error } = await supabase
            .from('texts')
            .select()
            .returns<ITextInfo[]>()

        if (error && !toast.isActive(toastId)) {
            setLoading(false)
            toast({
                id: toastId,
                title: 'Issue',
                description: 'Could not load texts',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
            })
        }

        if (data) {
            setLoading(false)
            setTexts(data)
        }
    }, [toast])

    useEffect(() => {
        loadTexts()
    }, [loadTexts])

    return (
        <HStack alignItems="flex-start" width="100%">
            <VStack
                spacing="8"
                background="white"
                padding={{
                    base: '16px 8px',
                    md: '24px',
                }}
                borderRadius={{
                    base: '8px',
                    md: '24px',
                }}
                minWidth={{
                    base: '100%',
                    md: 'container.md',
                }}
                width="100%"
            >
                <Heading textAlign="center" color={'twitter.800'}>
                    Texts
                </Heading>
                <TableContainer width="100%">
                    <Table variant="simple">
                        <TableCaption>Current online corpus</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Title</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {loading && (
                                <Tr>
                                    <Td>
                                        <Spinner
                                            thickness="4px"
                                            speed="0.65s"
                                            emptyColor="gray.200"
                                            color="teal.500"
                                            size="md"
                                        />
                                    </Td>
                                </Tr>
                            )}
                            {!texts.length && !loading && (
                                <Tr>
                                    <Td>No texts available</Td>
                                </Tr>
                            )}
                            {texts.map((text) => (
                                <Tr
                                    _hover={{
                                        backgroundColor:
                                            'var(--chakra-colors-gray-100)',
                                        cursor: 'pointer',
                                    }}
                                    key={`texts-${text.id}`}
                                    onClick={() => {
                                        navigate(`/texts/${text.seo}`)
                                    }}
                                >
                                    <Td>{text.title}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </HStack>
    )
}

export default Texts
