import { Input, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'

const syriacMapping = {
    '>': 'ܐ',
    B: 'ܒ',
    G: 'ܓ',
    D: 'ܕ',
    H: 'ܗ',
    W: 'ܘ',
    Z: 'ܙ',
    X: 'ܚ',
    V: 'ܛ',
    J: 'ܝ',
    K: 'ܟ',
    L: 'ܠ',
    M: 'ܡ',
    N: 'ܢ',
    S: 'ܣ',
    '<': 'ܥ',
    P: 'ܦ',
    Y: 'ܨ',
    Q: 'ܩ',
    R: 'ܪ',
    C: 'ܫ',
    T: 'ܬ',
    ' ': ' ',
}

type SyriacEncodingLetter = keyof typeof syriacMapping

export const convertEncodingToUnicode = (input: string) => {
    let convertedString = ''

    for (const l of input.replace(/\((\w|[><])|:dp|:d|:p/gm, '')) {
        if (
            Object.prototype.hasOwnProperty.call(syriacMapping, l.toUpperCase())
        ) {
            convertedString =
                convertedString +
                syriacMapping[l.toUpperCase() as SyriacEncodingLetter]
        }
    }

    return convertedString
}

const interpret = (ark: string) => {
    for (const token of ark.split('-')) {
        console.log(token)
    }
}

const Decoder = () => {
    const [unicodeConversion, setUnicodeConversion] = useState('')

    return (
        <VStack>
            <Input
                type="text"
                onChange={(e) => {
                    alert('change')
                    for (const ark of e.target.value.split(' ')) {
                        interpret(ark.toUpperCase())
                    }

                    setUnicodeConversion(
                        convertEncodingToUnicode(e.target.value)
                    )
                }}
            />
            <Text
                fontFamily="Estrangelo"
                fontSize="3xl"
                width="100%"
                style={{
                    direction: 'rtl',
                }}
            >
                {unicodeConversion}
            </Text>
        </VStack>
    )
}

export default Decoder
