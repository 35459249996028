import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Img,
    Input,
    Link,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import Logo from '../components/Logo'
import { supabase } from '../supabaseClient'

interface ILoginForm {
    email: string
    password: string
    api: string
}

const Login = () => {
    const initialValues: ILoginForm = { email: '', password: '', api: '' }

    const navigate = useNavigate()

    function validate(value: string) {
        let error
        if (!value) {
            error = 'Required'
        }

        return error
    }

    return (
        <Flex
            justifyContent="center"
            alignItems={{
                base: 'stretch',
                md: 'center',
            }}
            minWidth="100vw"
            minHeight="100vh"
            background="gray.200"
            padding={{
                base: '8px',
                md: 0,
            }}
        >
            <Stack
                flexDir="column"
                background="white"
                padding="48px"
                borderRadius={{
                    base: '8px',
                    md: '16px',
                }}
                justifyContent={{
                    base: 'flex-start',
                    md: 'center',
                }}
                minW={{
                    base: '100%',
                    md: 'auto',
                }}
                minH={{
                    base: '100%',
                    md: 'auto',
                }}
            >
                <VStack spacing={0}>
                    <Logo boxSize="96px" fill="teal" />
                    <Heading color="teal">Qoroyo</Heading>
                </VStack>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={async (values, actions) => {
                        try {
                            const { error } =
                                await supabase.auth.signInWithPassword({
                                    email: values.email,
                                    password: values.password,
                                })

                            if (error) throw error

                            navigate('/')
                        } catch (error: any) {
                            actions.setErrors({ api: error.message })
                        }
                    }}
                >
                    {({ isSubmitting, errors }) => (
                        <Form>
                            <VStack spacing="16px">
                                <Field name="email" validate={validate}>
                                    {({
                                        field,
                                        form,
                                    }: FieldProps<any, ILoginForm>) => (
                                        <FormControl
                                            isInvalid={
                                                (form.errors.email &&
                                                    form.touched
                                                        .email) as boolean
                                            }
                                        >
                                            <FormLabel>Email</FormLabel>
                                            <Input
                                                {...field}
                                                placeholder="email"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.email}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="password" validate={validate}>
                                    {({
                                        field,
                                        form,
                                    }: FieldProps<any, ILoginForm>) => (
                                        <FormControl
                                            isInvalid={
                                                (form.errors.password &&
                                                    form.touched
                                                        .password) as boolean
                                            }
                                        >
                                            <FormLabel>Password</FormLabel>
                                            <Input
                                                type="password"
                                                {...field}
                                                placeholder="password"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.password}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {errors.api && (
                                    <Text color="red">{errors.api}</Text>
                                )}
                                <Link
                                    as={RouterLink}
                                    to="/requestpasswordreset"
                                >
                                    Forgot password?
                                </Link>
                                <Button
                                    mt={4}
                                    colorScheme="teal"
                                    isLoading={isSubmitting}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                                <VStack
                                    spacing={0}
                                    style={{ marginTop: '2rem' }}
                                >
                                    <Text size="xs" color={'gray'}>
                                        A project by:{' '}
                                    </Text>
                                    <Img src="/etcbclogo.png" maxW={'6rem'} />
                                </VStack>
                            </VStack>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </Flex>
    )
}

export default Login
