import { useMemo } from 'react'
import { convertEncodingToUnicode } from '../Decoder'
import * as diff from 'diff'
import {
    SyriacWesternParagraph,
    SyriacEasternParagraph,
} from '../../pages/Reader'

export const SurfaceTextDiff: React.FC<{
    westernScript: boolean
    surfaceText: string
    newProposedValue: string
}> = ({ westernScript, surfaceText, newProposedValue }) => {
    const changes = useMemo(() => {
        return diff.diffChars(
            convertEncodingToUnicode(surfaceText),
            convertEncodingToUnicode(newProposedValue)
        )
    }, [surfaceText, newProposedValue])

    return (
        <>
            {westernScript ? (
                <SyriacWesternParagraph
                    style={{
                        width: '100%',
                        fontSize: '1.8rem',
                        position: 'relative',
                    }}
                    direction="right"
                >
                    {changes.map((change) =>
                        change.removed ? (
                            <span style={{ color: 'red' }}>{change.value}</span>
                        ) : (
                            change.value
                        )
                    )}
                </SyriacWesternParagraph>
            ) : (
                <SyriacEasternParagraph
                    style={{
                        width: '100%',
                        fontSize: '1.3rem',
                    }}
                    direction="right"
                >
                    {changes.map((change) =>
                        change.removed ? (
                            <span style={{ color: 'red' }}>{change.value}</span>
                        ) : (
                            change.value
                        )
                    )}{' '}
                </SyriacEasternParagraph>
            )}
        </>
    )
}
