import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { RouterProvider, Navigate, createHashRouter } from 'react-router-dom'
import Login from './pages/Login'
import { ChakraProvider } from '@chakra-ui/react'
import { AuthProvider } from './contexts/Auth'
import PrivateRoute from './components/PrivateRoute'
import Signup from './pages/Signup'
import Dashboard from './pages/Dashboard'
import theme from './theme'
import Texts from './pages/Texts'
import Invite from './pages/Invite'
import SelectChapter from './pages/SelectChapter'
import SelectBook from './pages/SelectBook'
import Decoder from './components/Decoder'
import Approve from './pages/Approve'
import ForgotPassword from './pages/ForgotPassword'
import RequestNewPassword from './pages/RequestNewPassword'
import Reader from './pages/Reader'
import { ProfileProvider } from './contexts/Profile'
import Search from './pages/Search'

const router = createHashRouter([
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/test',
        element: <Decoder />,
    },
    {
        path: '/signup',
        element: <Signup />,
    },
    {
        path: '/requestpasswordreset',
        element: <RequestNewPassword />,
    },
    {
        path: '/resetpassword',
        element: <ForgotPassword />,
    },
    {
        path: '/',
        element: (
            <PrivateRoute>
                <Dashboard />
            </PrivateRoute>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="/texts" />,
            },

            {
                path: '/texts',
                children: [
                    {
                        path: '/texts/',
                        element: <Texts />,
                    },
                    {
                        path: '/texts/:textId',
                        element: <SelectBook />,
                    },
                    {
                        path: '/texts/:textId/:bookId/',
                        element: <SelectChapter />,
                    },
                    {
                        path: '/texts/:textId/:bookId/:chapterId/',
                        element: <Reader />,
                    },
                ],
            },
            {
                path: '/invite',
                element: <Invite />,
            },
            {
                path: '/approve',
                element: <Approve />,
            },
            {
                path: '/search',
                element: <Search />,
            },
        ],
    },
    {
        path: '/about',
        element: <div>About</div>,
    },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <AuthProvider>
                <ProfileProvider>
                    <RouterProvider router={router} />
                </ProfileProvider>
            </AuthProvider>
        </ChakraProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
