import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    Link,
    Stack,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import Logo from '../components/Logo'
import ChevronLeft from '../icons/ChevronLeft'
import { supabase } from '../supabaseClient'

interface IRequestNewPassword {
    email: string
    api: string
}

const RequestNewPassword = () => {
    const toast = useToast()
    const navigate = useNavigate()

    const initialValues: IRequestNewPassword = { email: '', api: '' }

    function validate(value: string) {
        let error
        if (!value) {
            error = 'Email is required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Not a valid email'
        } else {
            error = null
        }

        return error
    }

    return (
        <Flex
            justifyContent="center"
            width="100vw"
            height="100vh"
            background="gray.200"
            alignItems={{
                base: 'stretch',
                md: 'center',
            }}
            padding={{
                base: '8px',
                md: 0,
            }}
        >
            <VStack
                spacing={{
                    base: 0,
                    md: 3,
                }}
                w={{ base: '100%', md: 'auto' }}
            >
                <HStack
                    spacing={3}
                    justifyContent="flex-end"
                    w={'100%'}
                    display={{
                        base: 'none',
                        md: 'flex',
                    }}
                >
                    <Button
                        variant={'link'}
                        colorScheme={'gray'}
                        onClick={() => {
                            navigate(-1)
                        }}
                        fontFamily={"'Cormorant',sans-serif;"}
                        fontSize={'larger'}
                        leftIcon={<ChevronLeft />}
                    >
                        Back
                    </Button>
                </HStack>
                <Stack
                    padding="48px 24px"
                    flexDir="column"
                    mb={{
                        base: 0,
                        md: '2',
                    }}
                    background="white"
                    borderRadius={{
                        base: '8px',
                        md: '16px',
                    }}
                    justifyContent={{
                        base: 'flex-start',
                        md: 'center',
                    }}
                    minW={{
                        base: '100%',
                        md: 'auto',
                    }}
                    minH={{
                        base: '100%',
                        md: 'auto',
                    }}
                    alignItems="center"
                    spacing={5}
                >
                    <Heading color={'twitter.800'}>Reset password</Heading>
                    <Text maxW="13.625rem" color={'gray.500'} fontSize="sm">
                        A password reset link will be sent to a valid email.
                    </Text>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={async (values, actions) => {
                            const toastId = 'toast-request-new-password'
                            try {
                                const { data, error } =
                                    await supabase.auth.resetPasswordForEmail(
                                        values.email
                                    )

                                if (error) throw error

                                if (data && !toast.isActive(toastId)) {
                                    toast({
                                        id: toastId,
                                        title: 'Success',
                                        description: 'Email has been sent',
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                        variant: 'subtle',
                                        position: 'bottom',
                                    })
                                }
                            } catch (error: any) {
                                actions.setErrors({ api: error.message })
                            }
                        }}
                    >
                        {({ isSubmitting, errors }) => (
                            <Form>
                                <VStack spacing="16px">
                                    <Field name="email" validate={validate}>
                                        {({
                                            field,
                                            form,
                                        }: FieldProps<
                                            any,
                                            IRequestNewPassword
                                        >) => (
                                            <FormControl
                                                isInvalid={
                                                    (form.errors.email &&
                                                        form.touched
                                                            .email) as boolean
                                                }
                                            >
                                                <FormLabel>Email</FormLabel>
                                                <Input
                                                    type="email"
                                                    {...field}
                                                    placeholder="Email"
                                                />
                                                <FormErrorMessage>
                                                    {form.errors.email}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    {errors.api && (
                                        <Text color="red">{errors.api}</Text>
                                    )}
                                    <Button
                                        mt={4}
                                        colorScheme="teal"
                                        isLoading={isSubmitting}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </Stack>
                <HStack
                    justifyContent="center"
                    w={'100%'}
                    display={{
                        base: 'none',
                        md: 'flex',
                    }}
                >
                    <Heading color="teal" fontSize={'3xl'}>
                        Qoroyo
                    </Heading>
                </HStack>
            </VStack>
        </Flex>
    )
}

export default RequestNewPassword
