import {
    HStack,
    PopoverTrigger,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    Text,
    Portal,
    VStack,
    Tag,
    Divider,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useMemo } from 'react'
import { convertEncodingToUnicode } from '../Decoder'

const arkProperties = {
    ps: 'person',
    nu: 'number',
    gn: 'gender',
    sp: 'part-of-speech',
    st: 'state',
    vo: 'voice',
    vs: 'verbal stem',
    vt: 'verbal tense',
    ls: 'ls',
}

interface IMorpheme {
    lexeme: string[]
    pfm?: string[]
    pfx?: string[]
    vbs?: string[]
    vbe?: string[]
    nme?: string[]
    emf?: string[]
    pm?: string[]
    vpm?: string
}

export interface IWordLevelAnalysis {
    code: string
    surface: string
    gloss: string
    morphemes: IMorpheme
    functions: { [K in keyof typeof arkProperties]?: string }
    realisation: string
    lexeme?: string[]
    sharedLexeme?: boolean
}

export type IWordLevelAnalysisResponse = Array<Array<Array<IWordLevelAnalysis>>>

const PopoverContainer = styled(HStack)`
    display: inline-flex;
`

const WordLevelAnalysis: React.FC<{
    enableWesternScript: boolean
    text: string
    wla: Array<Array<IWordLevelAnalysis>>
}> = ({ enableWesternScript, text, wla }) => {
    const textBrokenIntoChunks = useMemo(() => {
        return text.split(' ')
    }, [text])

    if (wla && !wla.length) {
        return (
            <Text
                fontFamily={
                    enableWesternScript ? 'SertoJerusalem' : 'EstrangeloEdessa'
                }
                fontSize={enableWesternScript ? '3xl' : '2xl'}
                display="inline-flex"
            >
                {convertEncodingToUnicode(text)}
            </Text>
        )
    }

    return (
        <>
            {textBrokenIntoChunks.map((ark, arkIndex) => {
                console.log('WLA IS', wla)
                const hasSharedLexeme =
                    wla &&
                    wla[arkIndex].findIndex((w) => w.sharedLexeme === true) > -1

                return (
                    <PopoverContainer
                        key={`popover-container-${ark}-${arkIndex}`}
                    >
                        <Popover trigger="hover">
                            <PopoverTrigger>
                                <Text
                                    fontFamily={
                                        enableWesternScript
                                            ? 'SertoJerusalem'
                                            : 'EstrangeloEdessa'
                                    }
                                    fontSize={
                                        enableWesternScript ? '3xl' : '2xl'
                                    }
                                    display="inline-flex"
                                    border={hasSharedLexeme ? 'none' : 'none'}
                                    mb="2"
                                >
                                    {convertEncodingToUnicode(ark)}
                                </Text>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverHeader>
                                        ETCBC Analysis
                                    </PopoverHeader>
                                    <PopoverBody pb="4">
                                        {wla &&
                                            wla[arkIndex]?.map((w, wIndex) => {
                                                console.log()
                                                return (
                                                    <VStack
                                                        key={`popover-body-${w.code}-${wIndex}`}
                                                    >
                                                        <VStack
                                                            width="100%"
                                                            spacing={1}
                                                        >
                                                            <HStack width="100%">
                                                                <Tag>
                                                                    word:{' '}
                                                                </Tag>
                                                                <Text
                                                                    fontFamily={
                                                                        enableWesternScript
                                                                            ? 'SertoJerusalem'
                                                                            : 'EstrangeloEdessa'
                                                                    }
                                                                    fontSize="2xl"
                                                                >
                                                                    {convertEncodingToUnicode(
                                                                        w.code
                                                                    )}
                                                                </Text>
                                                            </HStack>
                                                            <HStack width="100%">
                                                                <Tag>
                                                                    gloss:{' '}
                                                                </Tag>
                                                                <Text
                                                                    fontSize="sm"
                                                                    fontFamily={
                                                                        'Amiko'
                                                                    }
                                                                >
                                                                    {w.gloss}
                                                                </Text>
                                                            </HStack>
                                                            {w.functions &&
                                                                Object.keys(
                                                                    w.functions
                                                                ).map(
                                                                    (
                                                                        f,
                                                                        fIndex
                                                                    ) => (
                                                                        <HStack
                                                                            width="100%"
                                                                            key={`function-${f}-${fIndex}`}
                                                                        >
                                                                            <Tag>
                                                                                {
                                                                                    arkProperties[
                                                                                        f as keyof typeof arkProperties
                                                                                    ]
                                                                                }
                                                                            </Tag>
                                                                            <Text
                                                                                fontSize="sm"
                                                                                fontFamily={
                                                                                    'Amiko'
                                                                                }
                                                                            >
                                                                                {
                                                                                    w
                                                                                        .functions[
                                                                                        f as keyof typeof arkProperties
                                                                                    ]
                                                                                }
                                                                            </Text>
                                                                        </HStack>
                                                                    )
                                                                )}
                                                        </VStack>
                                                        {wla[arkIndex].length >
                                                            1 &&
                                                            wla[arkIndex]
                                                                .length -
                                                                1 !==
                                                                wIndex && (
                                                                <Divider
                                                                    mb="2"
                                                                    mt="4"
                                                                    variant="dashed"
                                                                    style={{
                                                                        borderColor:
                                                                            'var(--chakra-colors-gray-500)',
                                                                    }}
                                                                />
                                                            )}
                                                    </VStack>
                                                )
                                            })}
                                    </PopoverBody>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                    </PopoverContainer>
                )
            })}
        </>
    )
}

export default WordLevelAnalysis
