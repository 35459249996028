import React, {
    useState,
    useRef,
    useCallback,
    useReducer,
    createContext,
    ReactNode,
    useContext,
    Dispatch,
} from 'react'
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
    ReactFlowInstance,
    Connection,
    Background,
    NodeToolbar,
    Position,
    Handle,
    Node,
    Edge,
} from 'reactflow'
import 'reactflow/dist/style.css'

import { HStack, VStack, Heading, Button } from '@chakra-ui/react'
import styled from '@emotion/styled'

let id = 0
const getId = () => `dndnode_${id++}`

const FlowStyle = styled.div`
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 400px;
    width: 100%;

    .reactflow-wrapper {
        flex-grow: 1;
        height: 100%;
    }
`
interface State {
    nodes: Node[]
    edges: Edge[]
}

const initialState: State = {
    nodes: [],
    edges: [],
}

type Action =
    | { type: 'ADD_NODE'; node: Node }
    | { type: 'ADD_CHILD_NODE'; parentId: string; childNode: Node }
    | { type: 'ADD_EDGE'; edge: Edge }

const FlowContext = createContext<{
    state: State
    dispatch: Dispatch<Action>
}>({
    state: initialState,
    dispatch: () => null,
})

const flowReducer = (state: State, action: Action): State => {
    let parentIndex = -1

    switch (action.type) {
        case 'ADD_NODE':
            return {
                ...state,
                nodes: [...state.nodes, action.node],
            }
        case 'ADD_CHILD_NODE':
            parentIndex = state.nodes.findIndex(
                (node) => node.id === action.parentId
            )
            if (parentIndex === -1) return state

            return {
                ...state,
                nodes: [
                    ...state.nodes,
                    { ...action.childNode, parentId: action.parentId },
                ],
            }
        case 'ADD_EDGE':
            return {
                ...state,
                edges: [...state.edges, action.edge],
            }
        default:
            return state
    }
}

export const FlowProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(flowReducer, initialState)

    return (
        <FlowContext.Provider value={{ state, dispatch }}>
            {children}
        </FlowContext.Provider>
    )
}

export const useFlow = () => useContext(FlowContext)

const NodeStyle = styled.div`
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    padding: 1rem;
    background: white;
    border-radius: 0.25rem;
`

function WordGroupNode(props: {
    data: {
        forceToolbarVisible: undefined | boolean
        toolbarPosition: Position | undefined
        label: string
    }
}) {
    const { state, dispatch } = useFlow()

    return (
        <>
            <NodeToolbar
                isVisible={props.data.forceToolbarVisible || undefined}
                position={props.data.toolbarPosition}
            >
                <HStack background={'white'}>
                    <Button colorScheme="gray" variant="outline">
                        Prep
                    </Button>
                    <Button colorScheme="gray" variant="outline">
                        Verb
                    </Button>
                    <Button colorScheme="gray" variant="outline">
                        Noun
                    </Button>
                </HStack>
            </NodeToolbar>
            <NodeStyle>
                {props.data?.label}
                {/* {!state.words.length && props.data?.label}
                <VStack>
                    {state.words.map((word) => (
                        <Button key={word.id}>{word.type}</Button>
                    ))}
                </VStack> */}
            </NodeStyle>
            <Handle type="target" position={Position.Left} />
        </>
    )
}

const nodeTypes = {
    group: WordGroupNode,
}

const SearchFlow = () => {
    const reactFlowWrapper = useRef(null)
    const [edges, setEdges, onEdgesChange] = useEdgesState([])
    const [reactFlowInstance, setReactFlowInstance] =
        useState<ReactFlowInstance | null>(null)

    const { state, dispatch } = useFlow()

    const onConnect = useCallback(
        (params: Edge<any> | Connection) =>
            setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    )

    const onDragOver = useCallback(
        (event: {
            preventDefault: () => void
            dataTransfer: { dropEffect: string }
        }) => {
            event.preventDefault()
            event.dataTransfer.dropEffect = 'move'
        },
        []
    )

    const onDrop = useCallback(
        (event: {
            preventDefault: () => void
            dataTransfer: { getData: (arg0: string) => string }
            clientX: number
            clientY: number
        }) => {
            event.preventDefault()

            if (reactFlowInstance) {
                const type = event.dataTransfer.getData('application/reactflow')

                // check if the dropped element is valid
                if (typeof type === 'undefined' || !type) {
                    return
                }

                // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
                // and you don't need to subtract the reactFlowBounds.left/top anymore
                // details: https://reactflow.dev/whats-new/2023-11-10
                const position = reactFlowInstance.screenToFlowPosition({
                    x: event.clientX,
                    y: event.clientY,
                })
                const newNode = {
                    id: getId(),
                    type,
                    position,
                    data: { label: `${type} node` },
                }

                // setNodes((nds) => nds.concat(newNode))
            }
        },
        [reactFlowInstance]
    )

    return (
        <HStack alignItems="flex-start" width="100%">
            <VStack
                spacing="5"
                background="white"
                padding="24px"
                borderRadius="24px"
                minWidth={'container.md'}
                width="100%"
            >
                <Heading textAlign="center" color={'twitter.800'}>
                    Search
                </Heading>
                <FlowStyle>
                    <Button
                        colorScheme="blue"
                        variant="solid"
                        onClick={() =>
                            dispatch({
                                type: 'ADD_NODE',
                                node: {
                                    id: '2',
                                    data: { label: 'Group A' },
                                    position: { x: 100, y: 100 },
                                    type: 'group',
                                },
                            })
                        }
                    >
                        Add Word Group
                    </Button>
                    <ReactFlowProvider>
                        <div
                            className="reactflow-wrapper"
                            ref={reactFlowWrapper}
                        >
                            <ReactFlow
                                nodes={state.nodes}
                                edges={edges}
                                // onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onInit={setReactFlowInstance}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                nodeTypes={nodeTypes}
                                fitView
                            >
                                <Background />
                                <Controls />
                            </ReactFlow>
                        </div>
                    </ReactFlowProvider>
                </FlowStyle>
            </VStack>
        </HStack>
    )
}

const Search = () => {
    return (
        <FlowProvider>
            <SearchFlow />
        </FlowProvider>
    )
}

export default Search
